import ScrollReveal from "scrollreveal";

//scrollreveal
ScrollReveal().reveal(".fade-in-bottom", {
  origin: "bottom",
  distance: "30px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 400,
  delay: 200
});

ScrollReveal().reveal(".fade-in-top", {
  origin: "top",
  distance: "30px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 500
});

ScrollReveal().reveal(".teaser-image img", {
  origin: "top",
  distance: "0",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 700,
  delay: 200
});

ScrollReveal().reveal(".teaser-content", {
  origin: "top",
  distance: "0",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 500,
  delay: 600
});